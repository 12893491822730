import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deviceIsView } from 'commons/src/commonFunctions';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import PageWrapperContent from 'commons/src/components/containers/PageWrapperContent';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import StandAloneSpinner from 'commons/src/img/StandAloneSpinner';
import usbcCable from 'commons/src/img/usbcCable.png';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import {
    DeviceTypeBatteryConfig,
    ErrorType,
    SensorInterval,
    SensorSampleInterval,
} from 'commons/src/models/commonTypeScript';
import { fetchBatteryLifeAtBuildingLevel } from '../../../api/buildingApi';
import BatteryLevelDisplayed from '../../device/dropdownOptions/BatteryLevelDisplayed';
import BatterySettingsSelector from '../../device/dropdownOptions/BatterySettingSelector';
import ToggleSensorOnOff from '../../device/dropdownOptions/ToggleSensorOnOff';
import styles from './ConfigureBatteryLife.module.scss';

type ParentProps = {
    locationId: string;
    configurations: DeviceTypeBatteryConfig[];
    deviceType: DeviceTypeNames;
    updateConfig: (deviceType: string, updatedIntervals: DeviceTypeBatteryConfig[]) => void;
    reducedSampleRate?: boolean;
    setReducedSampleRate: (deviceType: DeviceTypeNames, reducedSampleRate: boolean) => void;
};

export type Props = ParentProps;

export const ConfigureBatteryLifeComponent = ({
    locationId,
    configurations,
    updateConfig,
    deviceType,
    reducedSampleRate,
    setReducedSampleRate,
}: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const [batteryLife, setBatteryLife] = useState<number | undefined>();
    const [batteryLifeError, setBatteryLifeError] = useState<ErrorType | undefined>(undefined);
    const [batteryLifeLoading, setBatteryLifeLoading] = useState(true);

    const updateSensorIntervals = (sensor: string, value: number): void => {
        const updatedSensorInterval = configurations.map(interval =>
            interval.sensorType === sensor ? { ...interval, current: value } : interval
        );
        updateConfig(deviceType, updatedSensorInterval);
    };

    const { toggleOptions, selectorOptions } = configurations.reduce(
        (
            intervalOptions: { toggleOptions: SensorSampleInterval[]; selectorOptions: SensorSampleInterval[] },
            sensorIntervalOption
        ) => {
            const isOnOffToggle = sensorIntervalOption.options.length === 2 && sensorIntervalOption.options.includes(0);
            if (isOnOffToggle) {
                return { ...intervalOptions, toggleOptions: [...intervalOptions.toggleOptions, sensorIntervalOption] };
            }
            return { ...intervalOptions, selectorOptions: [...intervalOptions.selectorOptions, sensorIntervalOption] };
        },
        { toggleOptions: [], selectorOptions: [] }
    );

    const getAverageBatteryLifeForWavePlus = useCallback(
        async (sensorInterval: SensorInterval) => {
            await fetchBatteryLifeAtBuildingLevel(locationId, sensorInterval, deviceType)
                .then(response => {
                    setBatteryLife(response.batteryLifetimeEstimateInWeeks);
                    setBatteryLifeLoading(false);
                })
                .catch(err => {
                    setBatteryLifeError(err);
                    setBatteryLifeLoading(false);
                });
        },
        [deviceType]
    );

    const fetchBatteryLifeForDeviceType = (sensorInterval: SensorInterval): void => {
        setBatteryLifeLoading(true);
        getAverageBatteryLifeForWavePlus(sensorInterval).catch(err => {
            setBatteryLifeError(err);
            setBatteryLifeLoading(false);
        });
    };

    useEffect(() => {
        const initialSensorIntervals = configurations.reduce(
            (obj, interval) => ({ ...obj, [interval.sensorType]: interval.current }),
            {}
        );
        fetchBatteryLifeForDeviceType(initialSensorIntervals);
    }, [configurations]);

    const isViewDevice = deviceIsView(deviceType as DeviceTypeNames);
    return (
        <PageWrapperContent pageType="slim" marginTop>
            <div>
                {batteryLifeLoading ? (
                    <StandAloneSpinner />
                ) : (
                    <BatteryLevelDisplayed estimatedBatteryLife={batteryLife} estimateBatteryError={batteryLifeError} />
                )}
                <div className={styles.estimatedBatteryLifeText}>
                    * {txt('DeviceSettings.EstimatedLifetime')}
                    <br />
                </div>
            </div>
            <div className={styles.toggleContainer}>
                {toggleOptions.map(interval => (
                    <div key={`batteryOptions${interval.sensorType}`} className={styles.sensorSelector}>
                        <ToggleSensorOnOff
                            onSelect={updateSensorIntervals}
                            interval={interval}
                            selectedInterval={interval.current}
                            deviceType={deviceType}
                        />
                    </div>
                ))}
                {selectorOptions.map(interval => (
                    <div key={`batteryOptions${interval.sensorType}`} className={styles.sensorSelector}>
                        <BatterySettingsSelector
                            disabled={false}
                            onSelect={updateSensorIntervals}
                            interval={interval}
                            selectedInterval={interval.current}
                            slimWidth
                        />
                    </div>
                ))}
            </div>
            {(isViewDevice || deviceType === DeviceTypeNames.spaceCo2Mini) && (
                <div>
                    {reducedSampleRate !== undefined ? (
                        <div className={styles.reduceSampleRate}>
                            <h4 className={styles.reduceSampleRateHeader}>
                                {txt('BuildingSettings.ReduceSampleRateHeader')}
                            </h4>
                            <FlipButton
                                id={`toggle-opening-hours-${deviceType}`}
                                onClick={(): void => setReducedSampleRate(deviceType, !reducedSampleRate)}
                                leftSelected={reducedSampleRate}
                                leftText="On"
                                rightText="Off"
                                capitalized
                            />
                        </div>
                    ) : (
                        <div className={styles.textWrapper}>
                            <MaterialIcon name="error_outline" />
                            {txt('BuildingSettings.BatteryLifeReducedHeader')}
                        </div>
                    )}
                    <div className={styles.bulletList}>
                        {txt(
                            deviceType === DeviceTypeNames.viewPlusBusiness
                                ? 'BuildingSettings.BatteryLifeViewPlusReducedText'
                                : 'BuildingSettings.BatteryLifeReducedText'
                        )}
                    </div>
                </div>
            )}
            {isViewDevice && (
                <div className={styles.textWrapper}>
                    <img src={usbcCable} alt={txt('DeviceSettings.USBCImage')} className={styles.usbCableImage} />
                    <div className={styles.subText}>{txt('DeviceSettings.USBPoweredDescription')}</div>
                </div>
            )}
        </PageWrapperContent>
    );
};

export default ConfigureBatteryLifeComponent;
