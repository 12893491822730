import { createSelector } from '@reduxjs/toolkit';
import { BuildingConfiguration, BuildingType } from 'commons/src/models/commonTypeScript';
import { Store } from '../index';

const getBuildingDetails = (state: Store, buildingId: string): BuildingType => {
    return state.buildings.buildings[buildingId] || ({} as BuildingType);
};

const getBuildingConfig = (state: Store, buildingId: string): BuildingConfiguration => {
    return state.buildings.configurations[buildingId];
};
export const buildingSelector = createSelector([getBuildingDetails], (building: BuildingType) => building);

export const buildingConfigSelector = createSelector(
    [getBuildingConfig],
    (configuration: BuildingConfiguration) => configuration
);

export default buildingSelector;
