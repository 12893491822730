import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_CONFIGURED_BATTERY_LIFE } from 'commons/src/analytics/AnalyticsEvents';
import { mapDeviceType } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ButtonWrapper from 'commons/src/components/containers/ButtonWrapper';
import PageWrapperContent from 'commons/src/components/containers/PageWrapperContent';
import ComponentTabNavigation from 'commons/src/components/headers/ComponentTabNavigation';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { DeviceTypeNames, SensorTypes } from 'commons/src/models/commonEnums';
import { AnyDeviceType, DeviceTypeBatteryConfig } from 'commons/src/models/commonTypeScript';
import { ComponentTab } from 'commons/src/models/menuModels';
import { updateLocationConfigWithDeviceType } from '../../../actions/locationActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { buildingConfigSelector } from '../../../reducers/selectors/buildingSelector';
import { RootState } from '../../../store';
import ConfigureBatteryLife from './ConfigureBatteryLife';

export type Props = {
    locationId: string;
    buildingDevices: string[];
};

type BatterySettings = {
    [deviceType: string]: DeviceTypeBatteryConfig[];
};

export const BatterySettingsComponent = ({ locationId, buildingDevices }: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const buildingConfig = useSelector((state: Store) => buildingConfigSelector(state, locationId));
    const { loading, error } = useSelector(
        (state: RootState) => state.requests[RequestType.UpdateLocationConfigurationWithDeviceType]
    );
    const { devicesWithKeyInfo } = useSelector((state: Store) => state.devices);

    const initialBatteryConfig = buildingConfig && buildingConfig.configuration.batteryConfigurations;
    const [batteryConfig, setBatteryConfig] = useState<BatterySettings>(initialBatteryConfig);
    const [reducedSampleRate, setReducedSampleRate] = useState(buildingConfig.configuration.reducedSampleRate);

    const tabOptions = initialBatteryConfig
        ? Object.keys(initialBatteryConfig).map(deviceType => ({
              deviceType,
              id: `displaySettings${deviceType}`,
          }))
        : [];

    const tabOptionsForDevicesInBuilding = tabOptions.filter(option =>
        buildingDevices.find(
            serialNumber =>
                devicesWithKeyInfo[serialNumber] && devicesWithKeyInfo[serialNumber].type === option.deviceType
        )
    );

    const [activeTab, setActiveTab] = useState(0);
    const [activeDeviceType, setActiveDeviceType] = useState<DeviceTypeNames | undefined>(
        tabOptionsForDevicesInBuilding[activeTab].deviceType as DeviceTypeNames
    );

    const updateSensorIntervals = (
        deviceType: string,
        updatedIntervals: { sensorType: SensorTypes; options: number[]; current: number }[]
    ): void => {
        const updatedSensorInterval: BatterySettings = {
            ...batteryConfig,
            [deviceType]: updatedIntervals,
        };
        setBatteryConfig(updatedSensorInterval);
    };

    const onSubmit = (): void => {
        analyticsLogger(BUILDING_CONFIGURED_BATTERY_LIFE, {
            selectedSensorIntervals: batteryConfig,
        });
        const updateConfigPayload = Object.keys(batteryConfig).reduce((payload, deviceType) => {
            const deviceConfig = batteryConfig[deviceType];
            const sensorConfigs = deviceConfig.reduce((sensorConfig, config) => {
                return {
                    ...sensorConfig,
                    [config.sensorType]: config.current,
                };
            }, {});
            return { ...payload, [deviceType]: sensorConfigs };
        }, {});
        dispatch(
            updateLocationConfigWithDeviceType(
                { selectedSensorIntervals: updateConfigPayload, reducedSampleRate },
                locationId
            )
        );
    };

    const resetConfig = (): void => {
        setBatteryConfig(initialBatteryConfig);
        setReducedSampleRate(buildingConfig.configuration.reducedSampleRate);
    };

    const selectTab = (index: number): void => {
        const deviceTypeTab = tabOptionsForDevicesInBuilding[index].deviceType;
        setActiveTab(index);
        setActiveDeviceType(deviceTypeTab as DeviceTypeNames);
        resetConfig();
    };

    const tabs: ComponentTab[] = tabOptionsForDevicesInBuilding.map(tab => ({
        title: txt(`${mapDeviceType(tab.deviceType as AnyDeviceType)}FullName`),
        id: tab.id,
        testAttr: `battery-settings-${tab.deviceType.toLowerCase()}`,
    }));

    const updateReducedSampleRate = (deviceType: DeviceTypeNames, value: boolean): void => {
        setReducedSampleRate({
            ...reducedSampleRate,
            [deviceType]: value,
        });
    };

    return (
        <PageWrapperContent pageType="full">
            <ComponentTabNavigation tabs={tabs} activeTab={activeTab} setActiveTab={selectTab} />
            {activeDeviceType && (
                <ConfigureBatteryLife
                    configurations={batteryConfig[activeDeviceType]}
                    updateConfig={updateSensorIntervals}
                    deviceType={activeDeviceType}
                    locationId={locationId}
                    reducedSampleRate={reducedSampleRate[activeDeviceType]}
                    setReducedSampleRate={updateReducedSampleRate}
                />
            )}
            {error && <ResponseBox text="SomethingWentWrong" subtext={txt(`ErrorCodes.${error.error}`)} />}
            <ButtonWrapper placement="center">
                <PrimaryButton
                    id="submit"
                    type="submit"
                    title="Save"
                    loading={loading}
                    color="primary"
                    onClick={onSubmit}
                />
            </ButtonWrapper>
        </PageWrapperContent>
    );
};

export default BatterySettingsComponent;
