import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { SensorTypes } from 'commons/src/models/commonEnums';
import {
    AverageBatterLifeForWavePlus,
    BuildingConfiguration,
    BuildingResponseType,
    LocationDetailsType,
    SensorInterval,
    UpdateBuildingConfig,
} from 'commons/src/models/commonTypeScript';
import {
    BreachType,
    BuildingInsight,
    HeatingCoolingData,
    PresenceByDevice,
    PresenceOverTime,
    PresenceWeekAggregation,
    UpdateBatteryAndScreenConfig,
} from '../models/buildingModels';
import { DownloadCsvPayload, LabelsPayload, ResetConnection, SensorBreachThresholds } from '../models/common';

export default async (location: LocationDetailsType): Promise<void> =>
    fetch(`/locations/${location.id}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(location),
    });

export const getBuilding = async (locationId: string): Promise<BuildingResponseType> =>
    fetch(`/locations/${locationId}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const getLocationLabels = async (locationId: string): Promise<{ labels: LabelsPayload }> =>
    fetch(`/locations/${locationId}/labels`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateLocationLabels = async (locationId: string, labels: { labels: LabelsPayload }): Promise<void> =>
    fetch(`/locations/${locationId}/labels`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(labels),
    });

export const fetchBatteryLifeAtBuildingLevel = async (
    locationId: string,
    sensorIntervals: SensorInterval,
    deviceType: string
): Promise<AverageBatterLifeForWavePlus> =>
    fetch(`/locations/${locationId}/configuration/calculate-battery-lifetime`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ sensorIntervals, deviceType }),
    });

export const getBuildingConfig = async (locationId: string): Promise<BuildingConfiguration> =>
    fetch(`/locations/${locationId}/configuration`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateBuildingConfig = async (locationId: string, config: UpdateBuildingConfig): Promise<JSON> =>
    fetch(`/locations/${locationId}/configuration`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(config),
    });

export const updateBuildingConfigWithDeviceType = async (
    locationId: string,
    config: UpdateBatteryAndScreenConfig
): Promise<void> =>
    fetch<void>(`/locations/${locationId}/configuration/device-settings`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(config),
    });

export const getBuildingResetConnections = async (locationId: string): Promise<{ devices: ResetConnection[] }> =>
    fetch(`/locations/${locationId}/reset-status`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const getBuildingSensorThresholdBreaches = async (
    locationId: string,
    from: string,
    to: string,
    useOpeningHours: boolean,
    resolution: string
): Promise<{ charts: BuildingInsight[]; thresholds: SensorBreachThresholds }> =>
    fetch(
        `/locations/${locationId}/breaches?useOpeningHours=${useOpeningHours}&from=${from}&to=${to}&resolution=${resolution}`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );

export const getSingleBuildingSensorThresholdBreaches = async (
    locationId: string,
    from: string,
    to: string,
    useOpeningHours: boolean,
    resolution: string,
    thresholds: { value: number; type: BreachType }[],
    chartId: string
): Promise<BuildingInsight> =>
    fetch(`/locations/${locationId}/breaches/${chartId}`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ from, to, useOpeningHours, resolution, thresholds }),
    });

export const deleteBuildingSensorThresholdBreach = async (chartId: string, buildingId: string): Promise<JSON> =>
    fetch(`/locations/${buildingId}/breaches/${chartId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const createBuildingSensorThresholdBreach = async (
    buildingId: string,
    from: string,
    to: string,
    useOpeningHours: boolean,
    resolution: string,
    sensor: SensorTypes
): Promise<BuildingInsight> =>
    fetch(`/locations/${buildingId}/breaches/`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ from, to, useOpeningHours, resolution, sensor }),
    });

export const updateBuildingSensorThresholdBreach = async (
    buildingId: string,
    chartId: string,
    thresholds: { value: number; type: BreachType }[],
    sensor: SensorTypes
): Promise<JSON> =>
    fetch(`/locations/${buildingId}/breaches/${chartId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ thresholds, sensor }),
    });

export const getHeatingAndCoolingOptimizationData = async (
    locationId: string,
    from: string,
    to: string
): Promise<{ results: HeatingCoolingData }> =>
    fetch<{ results: HeatingCoolingData }>(`/locations/${locationId}/energy-insights?from=${from}&to=${to}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const downloadCsv = async (locationId: string, payload: DownloadCsvPayload): Promise<void> =>
    fetch(`/locations/${locationId}/csv`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });

export const getPresenceInsightData = async (payload: {
    locationId: string;
    fromDate: string;
    toDate: string;
    openingHours: boolean;
    filter: { floor: string[]; roomType: string[] };
}): Promise<{ response: { presenceOverTime: PresenceOverTime[]; presenceByDevice: PresenceByDevice[] } }> => {
    const { locationId, filter, openingHours, toDate, fromDate } = payload;
    const floorFilter = filter.floor.length > 0 ? `&floor=${filter.floor.toString()}` : '';
    const roomTypeFilter = filter.roomType.length > 0 ? `&roomType=${filter.roomType.toString()}` : '';
    return fetch<{ response: { presenceOverTime: PresenceOverTime[]; presenceByDevice: PresenceByDevice[] } }>(
        `/locations/${locationId}/presence-insights?from=${fromDate}&to=${toDate}&useOpeningHours=${openingHours}${floorFilter}${roomTypeFilter}`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );
};

export const getPresenceWeekAggregationData = async (
    locationId: string,
    filter: { floor: string[]; roomType: string[] }
): Promise<{ response: PresenceWeekAggregation }> => {
    const floorFilter = filter.floor.length > 0 ? `floor=${filter.floor.toString()}` : '';
    const roomTypeFilter = filter.roomType.length > 0 ? `roomType=${filter.roomType.toString()}` : '';
    return fetch<{ response: PresenceWeekAggregation }>(
        `/locations/${locationId}/presence-insights/weekly-aggregate?${floorFilter}${roomTypeFilter}`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );
};
