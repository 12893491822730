import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './ButtonWrapper.module.scss';

type Props = {
    children: ReactElement | ReactElement[];
    placement?: 'center' | 'right';
};
const ButtonWrapper = ({ children, placement }: Props): ReactElement => {
    return (
        <div
            className={classNames(styles.buttonWrapper, {
                [styles.center]: placement === 'center',
                [styles.right]: placement === 'right',
            })}
        >
            {children}
        </div>
    );
};

export default ButtonWrapper;
