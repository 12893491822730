import React, { ReactElement } from 'react';
import classNames from 'classnames';
import style from './PageWrapperContent.module.scss';

type Props = {
    pageType: 'slim' | 'full';
    children: React.ReactNode;
    marginTop?: boolean;
};

const PageWrapperContent = ({ children, pageType, marginTop }: Props): ReactElement => {
    return (
        <div
            className={classNames(style.content, {
                [style.slim]: pageType === 'slim',
                [style.marginTop]: marginTop,
            })}
        >
            {children}
        </div>
    );
};

export default PageWrapperContent;
